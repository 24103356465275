import React, { useState } from 'react';
import { states } from './usStates';

const ThemeForm = ({ onSubmit, formSubmitted }) => {
  const [schoolName, setSchoolName] = useState('');
  const [schoolDistrict, setSchoolDistrict] = useState('');
  const [schoolCity, setSchoolCity] = useState('');
  const [schoolState, setSchoolState] = useState('');
  const [grades, setGrades] = useState([]);
  const [ethnicities, setEthnicities] = useState([]);
  const [studentCountries, setStudentCountries] = useState('');
  const [studentInterests, setStudentInterests] = useState('');
  const [schoolEvents, setSchoolEvents] = useState('');
  const [localEvents, setLocalEvents] = useState('');

  const handleGradeChange = (event) => {
    const value = event.target.value;
    setGrades((prev) =>
      prev.includes(value) ? prev.filter((grade) => grade !== value) : [...prev, value]
    );
  };

  const handleEthnicityChange = (event) => {
    const value = event.target.value;
    setEthnicities((prev) =>
      prev.includes(value) ? prev.filter((ethnicity) => ethnicity !== value) : [...prev, value]
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({
      schoolName,
      schoolDistrict,
      schoolCity,
      schoolState,
      grades,
      ethnicities,
      studentCountries,
      studentInterests,
      schoolEvents,
      localEvents
    });
  };

  return (
    <form className="container mt-4" onSubmit={handleSubmit}>
      <h3 className="text-center"><span className="badge bg-secondary">Theme Brainstorming Helper</span></h3>
      <h6 className="text-center text-danger">If you need help brainstorming themes for your project, fill out the survey below!</h6>

      <div className="mb-3">
        <label className="form-label">Your school name:</label>
        <input
          type="text"
          className="form-control"
          value={schoolName}
          onChange={(e) => setSchoolName(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Your school district:</label>
        <input
          type="text"
          className="form-control"
          value={schoolDistrict}
          onChange={(e) => setSchoolDistrict(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Your school city/town:</label>
        <input
          type="text"
          className="form-control"
          value={schoolCity}
          onChange={(e) => setSchoolCity(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Your school state:</label>
        <select
          className="form-control"
          value={schoolState}
          onChange={(e) => setSchoolState(e.target.value)}
          required
        >
          <option value="">Select a state</option>
          {states.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label className="form-label">Grade you want to use this customized project for:</label>
        <div className="form-check">
          {['3rd', '4th', '5th', '6th', '7th', '8th'].map((grade) => (
            <div key={grade}>
              <input
                className="form-check-input"
                type="checkbox"
                value={`${grade}`}
                onChange={handleGradeChange}
              />
              <label className="form-check-label">{grade} grade</label>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Race/ethnicities of your students:</label>
        <div className="form-check">
          {['American Indian or Alaska Native', 'Asian', 'Black or African American', 'Hispanic or Latino(a)', 'Native Hawaiian or Other Pacific Islander', 'White', 'Other'].map((ethnicity) => (
            <div key={ethnicity}>
              <input
                className="form-check-input"
                type="checkbox"
                value={ethnicity}
                onChange={handleEthnicityChange}
              />
              <label className="form-check-label">{ethnicity}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Countries where your students come from:</label>
        <input
          type="text"
          className="form-control"
          value={studentCountries}
          onChange={(e) => setStudentCountries(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">(Optional) Some shared interests/hobbies among your students:</label>
        <input
          type="text"
          className="form-control"
          value={studentInterests}
          onChange={(e) => setStudentInterests(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">(Optional) Some popular activities or events at your school:</label>
        <input
          type="text"
          className="form-control"
          value={schoolEvents}
          onChange={(e) => setSchoolEvents(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">(Optional) Some popular activities or events at your city/town:</label>
        <input
          type="text"
          className="form-control"
          value={localEvents}
          onChange={(e) => setLocalEvents(e.target.value)}
        />
      </div>
      <div className="text-center">
        <button type="submit" className="btn btn-primary" disabled={formSubmitted}>
          Generate themes
        </button>
      </div>
    </form>
  );
};

export default ThemeForm;