import React, { useState } from 'react';
import { Modal, Form, Input, Radio, Slider, Button, Space } from 'antd';
import '../../index.css';

function Survey({ visible, onSubmit, onCancel, suggestedThemes = [], suggestedProjectTitles = [] }) {
  const [form] = Form.useForm();
  const [showOther, setShowOther] = useState(false);

  const handleFinish = (values) => {
    onSubmit(values);
    form.resetFields();
    setShowOther(false);
  };

  const handleQuestion6Change = (e) => {
    if (e.target.value === 'other') {
      setShowOther(true);
    } else {
      setShowOther(false);
      // Reset the otherReason field if not 'other'
      form.setFieldsValue({ otherReason: '' });
    }
  };

  // Helper function to format examples
  const formatExamples = (examples, isProject = false) => {
    if (!examples || examples.length === 0) return '';
    const displayExamples = isProject
      ? examples.slice(0, 4).map(title => `"${title}"`).join(', ')
      : examples.slice(0, 4).join(', ');
    return ` (e.g., ${displayExamples})`;
  };

  return (
    <Modal
      visible={visible}
      // title="Mini Survey"
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      width={570}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        {/* Conditionally Render Theme Satisfaction Question */}
        {suggestedThemes.length > 0 && (
          <Form.Item
            name="theme_satisfaction"
            label={
              <strong>
                How would you rate the quality of the <em>theme</em> suggestions
                {formatExamples(suggestedThemes)}
                ?
              </strong>
            }
            rules={[{ required: true, message: 'Please rate the theme suggestions!' }]}
          >
            <Slider
              min={1}
              max={5}
              marks={{
                1: <span className="slider-mark">Very<br />Poor</span>,
                2: <span className="slider-mark">Poor</span>,
                3: <span className="slider-mark">Acceptable</span>,
                4: <span className="slider-mark">Good</span>,
                5: <span className="slider-mark">Very Good</span>,
              }}
              step={1}
            />
          </Form.Item>
        )}

        {/* Project Idea Sastifaction Question */}
        <Form.Item
          name="project_satisfaction"
          label={
            <strong>
              How would you rate the quality of the <em>project</em> suggestions
              {formatExamples(suggestedProjectTitles, true)}
              ?
            </strong>
          }
          rules={[{ required: true, message: 'Please rate the project suggestions!' }]}
        >
          <Slider
            min={1}
            max={5}
            marks={{
              1: <span className="slider-mark">Very<br />Poor</span>,
              2: <span className="slider-mark">Poor</span>,
              3: <span className="slider-mark">Acceptable</span>,
              4: <span className="slider-mark">Good</span>,
              5: <span className="slider-mark">Very Good</span>,
            }}
            step={1}
          />
        </Form.Item>

        {/* Image Satisfaction Question*/}
        <Form.Item
          name="image_satisfaction"
          label={<strong>How would you rate the quality of the <em>image</em> suggestions?</strong>}
          rules={[{ required: true, message: 'Please rate the image suggestions!' }]}
        >
          <Slider
            min={1}
            max={5}
            marks={{
              1: <span className="slider-mark">Very<br />Poor</span>,
              2: <span className="slider-mark">Poor</span>,
              3: <span className="slider-mark">Acceptable</span>,
              4: <span className="slider-mark">Good</span>,
              5: <span className="slider-mark">Very Good</span>,
            }}
            step={1}
          />
        </Form.Item>

        {/* Open-Ended Question */}
        <Form.Item
          name="theme_reason"
          label={<strong>Why did you choose this theme?</strong>}
          rules={[{ required: true, message: 'Please provide a reason!' }]}
        >
          <Input.TextArea rows={4} placeholder="Your reason" />
        </Form.Item>

        {/* Multiple-Choice Question with "Other" Option */}
        <Form.Item
          name="classroom_plan"
          label={<strong>Do you plan to use this project in your classroom?</strong>}
          rules={[{ required: true, message: 'Please select an option!' }]}
        >
          <Radio.Group onChange={handleQuestion6Change}>
            <Radio value="yes">Yes</Radio><br />
            <Radio value="no_playing">No, because I was just playing around with the tool</Radio><br />
            <Radio value="no_not_fit">No, because I don’t think this project is a good fit for my classroom</Radio><br />
            <Radio value="other">No, other reason</Radio>
          </Radio.Group>
        </Form.Item>

        {/* Conditional Input for "Other" Option */}
        {showOther && (
          <Form.Item
            name="other_reason"
            label="Please specify your reason:"
            rules={[{ required: true, message: 'Please specify your reason!' }]}
          >
            <Input.TextArea rows={3} placeholder="Your reason" />
          </Form.Item>
        )}

<Form.Item label={<><strong>Student Facing Materials</strong>(Optional)</>}>
If you would like to Conjuror to generate student facing materials for you, please
enter an email where you would like to recieve such materials.
<br/>
<br/>
<Space>
        <Form.Item
          name="email"
          rules={[
            {
              required: false,
              message: 'Please input your email',
            },
            {
              type: 'email',
              message: 'Please input a valid email',
            },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        
        {/* Radio buttons inline next to the email field */}
        <Form.Item
          name="materialType"
          initialValue="both"
          rules={[{ required: false, message: '' }]}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="docs">Google Docs</Radio.Button>
            <Radio.Button value="forms">Google Forms</Radio.Button>
            <Radio.Button value="both">Both</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Space>
      </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Submit Survey & Download Project
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Survey;