import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row, Spin,Button } from 'antd';
import NavBarPic from './NavBar/NavBarPic';
import ReactMarkdown from 'react-markdown';
import {generateSuggestions, logData} from '../api.js'
import { useAuth } from '../useAuth'
import SuggestionsOverview from './SuggestionsOverview';
import GenerationOverview from './GenerationOverview';
import axios from 'axios';
import {server} from '../api';
import Survey from './Form/Survey';

function PicStormer() {
  const location = useLocation();
  const { project, theme, module } = location.state || {};

  const [projectIdea, setProjectIdea] = useState(project || '');
  const [projectTheme, setProjectTheme] = useState(theme || '');
  const [projectModule, setProjectModule] = useState(module || '');
  const [generateMode, setGenerateMode] = useState(false);
  const [generateSpriteType, setGenerateSpriteType] = useState('');
  const [generateSpriteName, setGenerateSpriteName] = useState('');
  const [spriteTypes, setSpriteTypes] = useState({});
  const [spriteShortNames, setSpriteShortNames] = useState({});
  const [spriteNames, setSpriteNames] = useState([]);
  const [sprites, setSprites] = useState([]);
  const [selectedSprites, setSelectedSprites] = useState({});
  const [recievedSuggestions, setRecievedSuggestions] = useState(false);

  const [showSurvey, setShowSurvey] = useState(false);
  const [suggestedThemes, setSuggestedThemesLocal] = useState([]);
  const [suggestedProjectTitles, setSuggestedProjectTitlesLocal] = useState([]);


  const auth = useAuth();
  const rc = auth.getResearchCode();

  const parseProjectIdea = (idea) => {
    const projectPattern = /### Project name: [\s\S]*?(?=### Project name:|$)/g;
    const projects = idea.match(projectPattern) || [];
    const sprites = [];
    let backdrop = '';

    projects.forEach(project => {
      const spritesInProject = [];
      
      // Extract project name
      const namePattern = /### Project name: (.+)/;
      const nameMatch = project.match(namePattern);
      const projectName = nameMatch ? nameMatch[1].trim() : '';

      // Extract backdrop name
      // const backdropPattern = /\*\*Backdrop:\*\* (.+)/;
      // const backdropMatch = project.match(backdropPattern);
      // const backdrop = backdropMatch ? backdropMatch[1].trim() : '';

      const backdropPattern = /\*\*Backdrop:\*\* (.+)/;
      const backdropMatch = project.match(backdropPattern);
      if (backdropMatch) {
        backdrop = backdropMatch[1];
      }

      // Extract sprites
      const spritePattern = /- Sprite \d+: (.+?) \(type: (.+?) \| name: (.+?)\)/g;
      const matches = Array.from(project.matchAll(spritePattern));
      
      matches.forEach(match => {
        const spriteName = match[1].trim();
        const spriteType = match[2].trim();

        // TODO: If using shortName for student-facing materials, need to update
        // the return value to include shortName in each 'spritesInProject'
        const spriteShortName = match[3].trim();

        spritesInProject.push({ name: spriteName, type: spriteType, shortName: spriteShortName });
      });

      sprites.push(spritesInProject);
    });

    console.log('Backdrop:', backdrop);
    console.log('Sprites:', sprites);

    return { backdrop, sprites };
  };


  const handleSurveySubmit = async (surveyValues) => {
    // Log the survey data
    logData(rc, 'PICSTORMER_SURVEY_SUBMIT', surveyValues, (response) => {
      console.log('Survey data logged:', response);
    });

    // Proceed to download the project after logging survey data
    try {
      const response = await axios({
        url: `${server}/download_project`, // URL of your Flask endpoint
        method: 'POST', // Assuming the Flask endpoint is a POST request
        responseType: 'blob', // Important
        data: {
          selectedSprites: selectedSprites,
          sprites: sprites,
          shortNames:spriteShortNames,
          spriteTypes: spriteTypes,
          orderedSprites: spriteNames,
          module: module,
          materials:surveyValues.materialType,
          email:surveyValues.email,
          researchCode: rc

          // Add any necessary data if required by the POST request
        }
      });

      // Create a URL for the file and download it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'project.sb3');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      logData(rc, 'PICSTORMER_DOWNLOAD', {
        selectedSprites: selectedSprites,
        sprites: sprites,
        spriteTypes: spriteTypes,
        orderedSprites: spriteNames,
        module: module
      });
    } catch (error) {
      console.error('Error downloading the file', error);
    } finally {
      setShowSurvey(false);
      // Optional: Clear the stored suggestions after download
      // localStorage.removeItem('suggestedThemes');
      // localStorage.removeItem('suggestedProjectTitles');
    }
  };

  
  const handleDownload = () => {
    setShowSurvey(true); // Show the survey instead of downloading directly
  };

  const generateCallback = (spriteName) =>{
    setGenerateMode(true)
    setGenerateSpriteName(spriteName)

    console.log(spriteTypes[spriteName])
    setGenerateSpriteType(spriteTypes[spriteName])
  }

  const updateGeneratedSelections = (selections) =>{
    const i = spriteNames.indexOf(generateSpriteName)
    const new_sprites = [...sprites[i], ...selections]
    const prevLength = sprites[i].length;

    const copiedArray = [];
  
    // Loop through each sub-array in the original array
    for (let i = 0; i < sprites.length; i++) {
        // Copy each sub-array using slice (or any other method for deep copying arrays)
        const copiedSubArray = sprites[i].slice();
        // Add the copied sub-array to the new array
        copiedArray.push(copiedSubArray);
    }
    // for()
    
    const prevSelections = selectedSprites[generateSpriteName]
    const updatedSelections = prevSelections == null ? [] : [...prevSelections, ...selections.map((v, i) =>i+prevLength)]
    // console.log(`prev selections: ${prevSelections} updatedSelections: ${updatedSelections}`)

    copiedArray[i] = new_sprites;
    setSprites(copiedArray);
    setGenerateMode(false);
    updateSelections(generateSpriteName, updatedSelections);



    // dumm
    // console.log(i)


  }

  const updateSelections = (spriteToUpdate, selections) =>{
    // console.log(`${selections})
    // console.log(spriteToUpdate)
    const newSelections = structuredClone(selectedSprites);
    newSelections[spriteToUpdate] = selections;
    setSelectedSprites(newSelections);
    logData(rc,'PICSTORMER_SELECTION_UPDATED',{'Selections':newSelections, 'Sprites':sprites})
  }

  useEffect(() => {
    logData(rc, 'PICSTORMER_LOADED',{theme:theme,module:module})
    const spriteBackdrop = parseProjectIdea(projectIdea)
    const tempSpriteData = spriteBackdrop['sprites'][0];

    const backdrop = spriteBackdrop['backdrop']
    // tempSpriteData.push({'name':backdrop, 'type':'backdrop'})
    tempSpriteData.push({'name': backdrop, 'type': 'backdrop', 'shortName': 'Backdrop' });
    const tempSpriteNames = tempSpriteData.map((s)=>s.name);

    const tempSpriteTypes = tempSpriteData.reduce((acc, current) => {
      acc[current.name] = current.type;
      return acc;
    }, {});

    const tempSpriteShortNames = tempSpriteData.reduce((acc, current) => {
      acc[current.name] = current.shortName;
      return acc;
    }, {});


    console.log(tempSpriteData,'=<')
    // tempSpriteData.push()
    // console.log(tempSpriteTypes,'=====')
    // tempSpriteTypes[backdrop] = 'backdrop'
    // tempSpriteNames.push(backdrop)
    console.log(tempSpriteNames,'=====')

    setSpriteTypes(tempSpriteTypes);
    setSpriteNames(tempSpriteNames);
    setSpriteShortNames(tempSpriteShortNames);
    setRecievedSuggestions(false);
    generateSuggestions(tempSpriteData, (r)=>{
        setSprites(r.data.suggestions);
        setRecievedSuggestions(true);

        logData(rc, 'PICSTORMER_SUGGESTIONS_RECIEVED', {spriteData:tempSpriteData, suggestions:r.data.suggestions})
      });


  const cat = "https://en.scratch-wiki.info/w/images/ScratchCat3.0.svg"
  const dummySprites = []
  for(var i = 0; i < tempSpriteNames.length; i++){
    dummySprites.push([cat,cat])
  }
    setSprites(dummySprites);
    // setSprites([]);
    setSelectedSprites({});

    // logData('ResearchCodeTest', {'Foo':'Bar'})


    if (theme) setProjectTheme(theme);
    if (module) setProjectModule(module);

    // Retrieve suggested themes and project titles from localStorage
    const storedThemes = localStorage.getItem('suggestedThemes');
    if (storedThemes) {
      setSuggestedThemesLocal(JSON.parse(storedThemes));
    }

    const storedProjectTitles = localStorage.getItem('suggestedProjectTitles');
    if (storedProjectTitles) {
      setSuggestedProjectTitlesLocal(JSON.parse(storedProjectTitles));
    }

  }, [theme, module, projectIdea, rc]);


  const allSpritesSelected = spriteNames.map((n) => selectedSprites[n] == null ? false : selectedSprites[n].length > 0).every(Boolean);
  // console.log(allSpritesSelected)
  return (
    <div className="row flex-nowrap">
      {/* Nav Bar */}
      <NavBarPic />

      {/* Main Content */}
      <div className="col py-3">
        <h1 className="text-center fw-bold">HARMONIZING SCRATCH ENCORE</h1>
        <h2 className="text-center">Step 4 of 5: Choose sprites and backdrop</h2>
        <hr className="mx-auto" width="75%"/>

        <div className="container">
          <div className="container mb-3 w-75">
            <div className="card bg-light">
              <div className="card-body">
                <h5 className="card-title">Module: {projectModule}</h5>
                <h6 className="card-subtitle mb-1 text-muted">Theme: {projectTheme}</h6>
                <ReactMarkdown>{projectIdea}</ReactMarkdown>
              </div>
            </div>
            {generateMode ? <GenerationOverview spriteName={generateSpriteName} spriteType={generateSpriteType} callback={(selections)=>{updateGeneratedSelections(selections)}}/> : 
              <>
                <Row>
                <Spin spinning={!recievedSuggestions}>
                  <SuggestionsOverview spriteNames={spriteNames} spriteSuggestions={sprites}
                    onGenerate={generateCallback} updateSelections={updateSelections} 
                    selected={selectedSprites}/>
                </Spin>
                </Row>
                <br/>
                <Row style={{textAlign:'center'}}>
                  <Col span={24}>
                    {allSpritesSelected ? 
                      <Button type='primary' onClick={handleDownload}>Download Project</Button> 
                      : 
                      <Button type='primary' disabled >Download Project</Button> 
                      }
                  </Col>
                </Row>

              </>}
          </div>
        </div>
      </div>

      {/* Survey Modal */}
      <Survey
        visible={showSurvey}
        onSubmit={handleSurveySubmit}
        onCancel={() => setShowSurvey(false)}
        suggestedThemes={suggestedThemes} // Pass suggested themes
        suggestedProjectTitles={suggestedProjectTitles} // Pass suggested project titles
      />

    </div>
  );
}

export default PicStormer;
