import React, { useState, useEffect } from 'react';

function CustomIdeaForm({ onSubmit }) {
  const module = localStorage.getItem('selectedModule');
  const [projectName, setProjectName] = useState('');
  const [backdrop, setBackdrop] = useState('');

  // const [sprites, setSprites] = useState([
  //   { description: '', type: '', name: '', functionality: '' },
  //   { description: '', type: '', name: '', functionality: '' },
  //   { description: '', type: '', name: '', functionality: '' },
  // ]);

  // Determine number of sprites based on module
  const initialSpriteCount = module === 'Events' ? 4 : 3;
  const [sprites, setSprites] = useState(
    Array.from({ length: initialSpriteCount }, () => ({
      description: '',
      type: '',
      name: '',
      functionality: '',
    }))
  );

  useEffect(() => {
    setSprites(
      Array.from({ length: initialSpriteCount }, () => ({
        description: '',
        type: '',
        name: '',
        functionality: '',
      }))
    );
  }, [module]);

  const handleSpriteChange = (index, field, value) => {
    const newSprites = [...sprites];
    newSprites[index][field] = value;
    setSprites(newSprites);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const customIdea = `
### Project name: ${projectName}
**Backdrop:** ${backdrop}

**Sprites:**
${sprites.map(
  (sprite, i) =>
    `- Sprite ${i + 1}: ${sprite.description} (type: ${sprite.type} | name: ${sprite.name})`
).join('\n')}

**Sprite functionality:**
${sprites.map(
  (sprite) => `- ${sprite.name}: ${sprite.functionality}`
).join('\n')}
    `;
    onSubmit(customIdea);
  };

  return (
    <div className="container mt-4">
      <h3 className="text-center">Enter Your Own Project Idea</h3>
      <h6 className="text-center text-danger">If you want to use your own project idea, fill out the form below!</h6>
      
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Project name:</label>
          <input
            type="text"
            className="form-control"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Backdrop:</label>
          <input
            type="text"
            className="form-control"
            value={backdrop}
            onChange={(e) => setBackdrop(e.target.value)}
            required
          />
        </div>
        {sprites.map((sprite, index) => (
          <div key={index} className="mb-3">
            {/*<label className="form-label">Sprite {index + 1}:</label>*/}

            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-xs-2">
                    <label className="form-label">Sprite {index + 1}</label>
                  </div>

                  <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Describe this sprite here"
                    value={sprite.description}
                    onChange={(e) => handleSpriteChange(index, 'description', e.target.value)}
                    required
                  />
                  </div>
                  <div className="form-check col-auto">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`sprite${index}Type`}
                      id={`sprite${index}TypeHuman`}
                      value="human"
                      checked={sprite.type === 'human'}
                      onChange={(e) => handleSpriteChange(index, 'type', e.target.value)}
                    />
                    <label className="form-check-label" htmlFor={`sprite${index}TypeHuman`}>
                      Human
                    </label>
                  </div>
                  <div className="form-check col-auto">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`sprite${index}Type`}
                      id={`sprite${index}TypeAnimal`}
                      value="animal"
                      checked={sprite.type === 'animal'}
                      onChange={(e) => handleSpriteChange(index, 'type', e.target.value)}
                    />
                    <label className="form-check-label" htmlFor={`sprite${index}TypeAnimal`}>
                      Animal
                    </label>
                  </div>
                  <div className="form-check col-auto">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`sprite${index}Type`}
                      id={`sprite${index}TypeObject`}
                      value="object"
                      checked={sprite.type === 'object'}
                      onChange={(e) => handleSpriteChange(index, 'type', e.target.value)}
                    />
                    <label className="form-check-label" htmlFor={`sprite${index}TypeObject`}>
                      Object
                    </label>
                  </div>
                </div>

                <input
                  type="text"
                  className="form-control"
                  value={sprite.name}
                  placeholder="What is the name of this sprite?"
                  onChange={(e) => handleSpriteChange(index, 'name', e.target.value)}
                  required
                />

                <input
                  type="text"
                  className="form-control mt-3"
                  value={sprite.functionality}
                  placeholder="How does this sprite animate?"
                  onChange={(e) => handleSpriteChange(index, 'functionality', e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        ))}
        <div className="text-center">
          <button type="submit" className="btn btn-primary">Use my own idea</button>
        </div>
      </form>
    </div>
  );
}

export default CustomIdeaForm;