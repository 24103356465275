import React,{ useState, useEffect } from 'react';
import { useAuth } from '../useAuth'
import { Button, Checkbox, Form, Input } from 'antd';
import { useNavigate } from "react-router-dom";
import logo from '../assets/images/conjurorLogo.png'


export const Login = () => {

  const [loginFailed, setLoginFailed] = useState(false)
  const auth = useAuth();
  let navigate = useNavigate();

  console.log(auth)
  const onFinish = (values) => {
    // console.log(useAuth())
    console.log('Success:', values.researchCode);

    return auth.signin(values.researchCode, ()=>{navigate('/')}, (e)=>{setLoginFailed(true);})
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
    }}
  >
      <img
      src={logo} // Using the imported logo variable
      alt="Logo"
      style={{
        width: '400px', // Set the image width
        height: 'auto',
        marginBottom: '20px', // Space between the image and form
      }}
    />
  <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Research Code"
      name="researchCode"
      rules={[
        {
          required: true,
          message: 'Please input your research code!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      {loginFailed ? 'Please try again':''}
      <br/>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
  <h3>
  Need a research code? Find out more <a href="https://docs.google.com/forms/d/e/1FAIpQLSdCe0GGHoyXCqYCt8U32BRo151xaiPMUH5Y9HqaqkTsZndvCg/viewform?usp=sf_link">here</a>
  </h3>
  </div>
);}